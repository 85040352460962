.App {
  text-align: center;
}

html { height: 100%; }

body,#root,.App {
  height: 100%;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.container{
  overflow-x: auto;
}

.navBarMenu:hover{
  color: black;
}


.active > h3{
  color:black !important;
}

.background-image {
  filter: blur(1px);
  height: 100%;
  width: 100%;
  position: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("components/assets/Background2.jpg");
  transform: scale(1.03);
  z-index: -99;
}

#logoContainer{
  height: 120px;
  width: 100%;
  text-align: center;
  /* margin-top: 60px; */
  /* position: absolute; */
  /* margin-left: 50%;
  margin-right: 50%; */
  z-index: 42;
}

#logoContainer img {
  height: 100%;
  
}

.nav-item:hover{
  text-decoration: overline;
  color: black;
}


#mainContainer{
  
  height: 82%;
}


#MyPhotoContainer{
  /* width: 100%; */
  height: 100%;
}

#MyPhoto{
height: 100%;
width: 100%;
object-fit: contain;

}

.h-95 {
  height: 95%!important;
}

.card {
  opacity: .91;
  
  /* margin: 0 auto; /* Added */
  /* float: none; Added */
  /* margin-bottom: 10px; Added */ 
  height: 100%!important;
  max-height: 100%!important;

  width: 100%!important;
  max-width: 100%!important;
  display: flex;
  flex-direction: column-reverse;

}

.badge-container{
  display: inline-flex;
}



/* .card-body{
  
} */

#textCopyright{
  margin-bottom: 0px;
  font-size: 9px;
}

#textMentionLegal{
  font-size: 10px;
}

#root > div > div.fixed-bottom.bg-white > div > footer {
  font-size: 5px;
}

.navBarMenu {
  color: white;
}


.width33Pur{
  width: 33%;
}

.tabsTecnics .nav-item .nav-link {
  color: black!important;
}


#tabsTecnics-tab:hover {
  text-decoration: none!important;
}
.tabsTecnics .nav-item {
  text-decoration: none!important;
  font-weight: bold;
}
.tabsTecnics .nav-link.active {
  background-color: #ca80dfc9;
  text-decoration: none!important;
}


/* .tab-content .tab-pane {
height: 100%!important;
} */
/* 
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

@media (max-width:801px)  { 
  .ImagePiece01Container, .ImagePiece02Container{
    display: none!important;
  }

  .logoHomeContainer{
    width: 100%!important;
    height: 80%!important;
    align-items: initial!important;
    place-content: center;
  }

  #logoHome{
    height: 150px!important;
    width: 150px!important;
    max-width: unset!important;
    max-height: unset!important;
  }

  .mobileBlock{
    display: block;
    height: unset!important;
  }

  .carteVisite{
    padding-top: 20px;
    display: inline!important;
    text-align: center!important;
  }

  .mobileCentrer{
    text-align: center!important;
  }

  .mainContainer {
    height: 72%;
  }

  .App {
    height: 96%;
  }
  #root {
    height: 92%;
  }

  .card{
    height: 84%!important;
  }

  #MyPhotoContainer{
    display: none;
  }

  .mobileNoMargin {
    margin: 0!important;
  }

  /* .mobileNoPadding {
    
  } */

.pe-5{
 padding-left: 1rem!important;
}

.ps-5 {
 padding-right: 1rem!important;
}

.mobileUnsetHeight {
  height: unset!important;
}

.mobileFontSizeLarger{
  font-size: large!important;
}

  /* tablet, landscape iPad, lo-res laptops ands desktops */ }

@media screen and  ( max-height: 768px )
{
  /* #MyPhotoContainer{
    width: 81%;
    height: 100%;
  } */

  /* #MyPhoto{
    bottom: 0px;
    height: 60%;
    width: 60%;
    object-fit: contain;
    
    } */
}
